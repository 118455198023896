import { FormDuufGroup, FormDuufSubmit } from '@ubo/form-duuf'
import type { LoaderData } from '@ubo/losse-sjedel'
import { LosseLink, LossePlaatjie, losseContentParse, useLoaderData } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { useInView } from 'framer-motion'
import type { Dispatch, SetStateAction } from 'react'
import { createContext, useContext, useEffect, useRef, useState } from 'react'
import Content from '~/components/elements/Content'
import Form from '~/components/elements/Form'
import Loading from '~/components/elements/Loading'
import Slider, { Slide } from '~/components/elements/Slider'
import type { Page_Flexcontent_Flex_Content, Page_Flexcontent_Flex_Content_Cta, Vacature } from '~/graphql/types'
import useMediaQuery from '~/hooks/useMediaQuery'
import { useUtmData } from '~/services/utm'

const SectionContext = createContext<{
  fields: Page_Flexcontent_Flex_Content | null
  page: Vacature | null
  accordionFields: Array<{ title?: string; subtitle?: string; description?: string; videourl?: string; bgcolor?: string; open?: boolean }>
  cta: Page_Flexcontent_Flex_Content_Cta | null
  setCurrentIndexes: Dispatch<SetStateAction<Array<any>>>
  currentIndexes: number[]
}>({
  fields: null,
  page: null,
  accordionFields: [],
  cta: null,
  setCurrentIndexes: (arr) => arr,
  currentIndexes: []
})

export default function ContentVacancy({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  const { page } = useLoaderData<LoaderData<Vacature>>()
  const [currentIndexes, setCurrentIndexes] = useState<number[]>([])

  const accordionFields = [
    {
      title: 'WAT GA JE DOEN?',
      // subtitle: 'Zo ziet je dag eruit',
      description: page.recap?.watgajedoen,
      subDescription: null,
      bgcolor: '#F3F3F3',
      open: true
    },
    {
      title: 'WAT NEEM JE MEE?',
      // subtitle: 'Functie-eisen',
      description: page.recap?.watneemjemee,
      subDescription: null,
      bgcolor: '#F3F3F3',
      open: true
    },
    {
      title: 'JOUW WERKGEBIED',
      // subtitle: 'Waar ga je werken',
      description: page.recap?.jouwwerkgebied,
      subDescription: null,
      bgcolor: '#F3F3F3',
      open: true
    },
    {
      title: 'JE KUNT REKENEN OP',
      // subtitle: 'Want dat verdien je',
      description: page.recap?.jekuntrekenenop,
      subDescription: null,
      bgcolor: '#DAF1FF',
      open: true
    },
    {
      title: 'OVER HEINEN & HOPMAN',
      // subtitle: 'Wie zijn wij',
      description: page.recap?.overHeinenEnHopman,
      videourl: page.recap?.videourl,
      bgcolor: '#F3F3F3',
      open: true
    }
  ].filter((it) => {
    return it.title === 'JOUW WERKGEBIED' && !it.description ? false : true
  })

  useEffect(() => {
    const initialIndexes: number[] = []
    // Run this code only once, after the initial render
    accordionFields.forEach((edge, i) => {
      const active = currentIndexes.includes(i)

      if (edge.open && !active) {
        initialIndexes.push(i)
      }
    })

    setCurrentIndexes(initialIndexes)
  }, [])

  const cta = fields.cta![0]
  const isDesktop = useMediaQuery('(min-width: 1024px)')

  return (
    // @ts-ignore
    <SectionContext.Provider value={{ fields, page, accordionFields, cta, setCurrentIndexes, currentIndexes }}>
      <section data-component="ContentVacancy" className="pt-14">
        <div className="lg:hidden">{!isDesktop && <Mobile />}</div>
        <div className="hidden lg:block">{isDesktop && <Desktop />}</div>
      </section>
    </SectionContext.Provider>
  )
}

function Mobile() {
  const { fields, page, cta } = useContext(SectionContext)

  return (
    <div>
      <Accordion />
      <div className="pl-4">
        {fields?.title && <Content className="children-headings:text-3xl children-headings:font-semibold py-6">{fields.title}</Content>}
        <Slider
          breakpoints={{
            0: {
              slidesPerView: 2.1,
              spaceBetween: 10
            }
          }}
          className="!pr-4"
        >
          {fields?.items?.map((edge) => {
            return (
              <Slide key={edge?.title} style={{ backgroundColor: '#DAF1FF' }} className="!h-auto">
                <div className="p-4 flex flex-col justify-between h-full">
                  <LossePlaatjie maxwidth={100} src={edge?.icon} className="mb-8" />
                  <h3 className="hyphens-auto text-base leading-[1.1] font-semibold">{losseContentParse(edge?.title)}</h3>
                </div>
              </Slide>
            )
          })}
        </Slider>
        <div className="my-8">{fields?.description && <Content>{fields.description}</Content>}</div>
        <Content id="solliciteren" className="children-headings:text-2xl children-headings:font-semibold mb-6">
          {fields?.applycta?.description}
        </Content>

        <ApplyForm />
      </div>
      <div className="relative flex flex-col pt-12 bg-hh-concrete px-4 overflow-hidden">
        <LossePlaatjie
          maxwidth={205}
          className="absolute -left-16 bottom-0 max-w-[205px]"
          src={page?.recap?.recruiter?.featuredImage?.node || cta?.image}
        />
        <div>
          <Content className="children-headings:text-xl children-headings:font-semibold children-headings:pr-20 xl:children-headings:pr-32 mb-4">
            {cta?.title}
          </Content>
          <Content className="text-hh-blue">
            {page?.recap?.recruiter?.recap?.firstName && page?.recap?.recruiter?.recap?.lastName && page.recap.recruiter.recap.function
              ? `Neem contact op met ${page.recap.recruiter.recap.firstName} ${page.recap.recruiter.recap.lastName}, ${page.recap.recruiter.recap.function}`
              : `${cta?.subtitle}`}
          </Content>
        </div>
        <div className="mb-5 pl-32 py-12">
          <CtaLinks />
        </div>
      </div>
    </div>
  )
}

function Desktop() {
  const accordionRef = useRef<HTMLDivElement>(null)
  const { fields, cta, page } = useContext(SectionContext)
  const [isSticky, setIsSticky] = useState(false)

  const sollicitatieRef = useRef(null)

  const sollicitatieInView = useInView(sollicitatieRef)

  useEffect(() => {
    const handleScroll = () => {
      const accordionElement = accordionRef.current
      if (accordionElement) {
        const accordionRect = accordionElement.getBoundingClientRect()
        const isSticky = window.scrollY >= accordionRect.top
        setIsSticky(isSticky)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    const stickyAnchor = document.getElementById('sticky-anchor')

    if (!sollicitatieInView) {
      if (stickyAnchor) {
        stickyAnchor?.classList.remove('hidden')
        stickyAnchor?.classList.add('block')
      }
    }
  }, [sollicitatieInView])

  return (
    <div className="container">
      <div className="grid grid-cols-12 gap-x-12">
        <div className="col-span-7">
          <div ref={accordionRef}>
            <Accordion />
          </div>
        </div>
        <div className="col-span-5">
          {fields?.items?.map((edge) => {
            return (
              <div key={edge?.title} className="flex items-center mb-5 px-4 py-5" style={{ backgroundColor: '#DAF1FF' }}>
                <LossePlaatjie maxwidth={1000} src={edge?.icon} className="mr-5" />
                <h3 className="text-2xl font-semibold pr-16 leading-[1.2] hyphens-auto">{losseContentParse(edge?.title)}</h3>
              </div>
            )
          })}
          <div
            className={clsx(
              isSticky && 'sticky top-32',
              'relative flex flex-col py-6 bg-hh-mine-shaft mt-8 text-white overflow-hidden px-4 lg:px-6'
            )}
          >
            <LossePlaatjie
              maxwidth={205}
              className="absolute -left-16 bottom-0 max-w-[205px]"
              src={page?.recap?.recruiter?.featuredImage?.node}
            />
            <div>
              <Content className="children-headings:text-xl xl:children-headings:text-2xl children-headings:font-semibold children-headings:pr-20 xl:children-headings:pr-12 mb-4">
                {cta?.title}
              </Content>
              <Content className="text-[14px] font-light mb-3">
                {page?.recap?.recruiter?.recap?.firstName && page?.recap?.recruiter?.recap?.lastName && page.recap.recruiter.recap.function
                  ? `<p>Neem contact op met ${page.recap.recruiter.recap.firstName} ${page.recap.recruiter.recap.lastName}, ${page.recap.recruiter.recap.function}</p>`
                  : `${cta?.subtitle}`}
              </Content>
            </div>
            <div className="max-lg:mb-5 xl:pl-28 lg:mb-52 xl:mb-5 xl:py-6">
              <CtaLinks />
            </div>
          </div>
        </div>
      </div>

      {/* <div className="grid grid-cols-12 gap-6 mt-16">
        <div className="col-span-5">
          <Content className="text-5xl children-headings:font-semibold">{fields?.title}</Content>
        </div>
        <div className="col-span-7">
          {page?.recap?.overHeinenEnHopman ? (
            <Content className="content">{page.recap.overHeinenEnHopman}</Content>
          ) : (
            <Content className="content">{fields?.description}</Content>
          )}
        </div>
      </div> */}
      <div ref={sollicitatieRef} className="grid grid-cols-12 gap-6 mt-16 mb-20" id="solliciteren">
        <div className="col-span-5">
          <Content className="text-5xl children-headings:font-semibold">{fields?.applycta?.description}</Content>
        </div>
        <div className="col-span-7">
          <ApplyForm />
        </div>
      </div>
    </div>
  )
}

function ApplyForm() {
  const { fields, page } = useContext(SectionContext)
  const [isLoaded, utmData] = useUtmData()

  if (!isLoaded) {
    return null
  }

  return (
    <div>
      <Content className="text-4xl uppercase children-headings:font-semibold mb-6 lg:block hidden">{`<h2>Solliciteer via het formulier</h2>`}</Content>

      <div className="relative lg:p-0 pr-4">
        <Form
          className="relative pb-16"
          data={fields?.applyForm}
          scrollToConfirm={false}
          renderLoading={() => (
            <div className="relative">
              <Loading className="text-hh-blue" />
            </div>
          )}
          renderStatus={({ status, content }) => {
            return (
              <>
                <div className="flex items-center justify-center text-white py-6">
                  {status === 'done' ? (
                    (window.location.href = '/bedankt')
                  ) : (
                    <div className="flex flex-col items-center justify-center gap-4 text-center">
                      <div className="bg-red-500 flex--center w-20 h-20 rounded-full box--shadow">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          className="w-20 h-20 text-white relative"
                          viewBox="0 0 24 24"
                        >
                          <path d="M18 6L6 18"></path>
                          <path d="M6 6L18 18"></path>
                        </svg>
                      </div>

                      <Content className="content children:!text-black mt-4 text-center text-xl lg:text-2xl font-medium">{`<p>Er is iets misgegaan. Controleer je bestandsgrootte of neem contact op met de recruiter.</p>`}</Content>
                    </div>
                  )}
                </div>
              </>
            )
          }}
          privacyUrl="/privacy-verklaring/"
          privacyUrlPlacement="privacybeleid"
          generate={false}
          defaultFields={{
            input_9: `${page?.databaseId}`,
            input_11: utmData.source,
            input_12: utmData.medium,
            input_13: utmData.content
          }}
          onSubmitDone={() => {
            // navigate to thank you page
            window.location.href = '/bedankt'
          }}
        >
          <div className="sm:grid grid-cols-2 flex flex-col">
            <div className="sm:pr-2">
              <FormDuufGroup showIds={[1]} />
            </div>
            <div className="sm:pl-2">
              <FormDuufGroup showIds={[3]} />
            </div>

            <div className="sm:pr-2">
              <FormDuufGroup showIds={[4]} />
            </div>
            <div className="sm:pl-2">
              <FormDuufGroup showIds={[5]} />
            </div>

            <div className="sm:col-span-2">
              <FormDuufGroup hideIds={[1, 3, 4, 5]} />
              <FormDuufSubmit />
            </div>
          </div>
        </Form>
      </div>
    </div>
  )
}

function Accordion() {
  const { accordionFields, setCurrentIndexes, currentIndexes } = useContext(SectionContext)

  const isDesktop = useMediaQuery('(min-width: 1024px)')

  return (
    <>
      {accordionFields.map((edge, i) => {
        const active = currentIndexes.includes(i)

        if (!edge.title && !edge.subtitle) return null

        return (
          <div
            key={edge.title}
            className={clsx(
              i < accordionFields.length - 1 && i < accordionFields.length - 2 ? 'border-b-hh-mine-shaft/10' : 'border-b-transparent',
              active && 'pb-10',
              'border-b-2'
            )}
            style={
              isDesktop ? { backgroundColor: edge.bgcolor } : { backgroundColor: edge.bgcolor === '#DAF1FF' ? '#DAF1FF' : 'transparent' }
            }
          >
            <button
              onClick={() => {
                const waf = currentIndexes.indexOf(i)
                const woef = [...currentIndexes]

                if (waf !== -1) {
                  woef.splice(waf, 1)
                } else {
                  woef.push(i)
                }

                setCurrentIndexes(woef)
              }}
              className="px-4 md:px-8 md:pt-8 pt-4 group w-full pb-4 md:pb-8"
            >
              <div>
                <div className="inline-flex justify-between items-center w-full">
                  <div>
                    <h2 className="text-2xl font-semibold group-hover:underline">{edge.title}</h2>
                  </div>
                  <svg
                    width="14"
                    height="8"
                    viewBox="0 0 14 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={clsx(!active && '-rotate-90', 'transition-transform')}
                  >
                    <path
                      d="M0.29669 1.81108L1.71164 0.400085L7.00821 5.68908L12.3048 0.400085L13.7197 1.81508L8.42316 7.10007C8.04785 7.47501 7.53889 7.68565 7.00821 7.68565C6.47752 7.68565 5.96856 7.47501 5.59326 7.10007L0.29669 1.81108Z"
                      fill="#323232"
                      fillOpacity="0.5"
                    />
                  </svg>
                </div>

                <h3 className="text-lg text-hh-blue text-left">{edge.subtitle}</h3>
              </div>
            </button>
            <div className="container">
              {active && (
                <>
                  {edge.description && (
                    <Content className={clsx(edge.bgcolor === '#DAF1FF' ? 'content-rounded content' : 'content', 'lg:pr-12')}>
                      {edge.description}
                    </Content>
                  )}
                  {/* {edge.subDescription && (
                    <Content className="mt-7 content [&_ul]:text-hh-mine-shaft lg:pr-12">{edge.subDescription}</Content>
                  )} */}
                  {edge.videourl && (
                    <video preload="metadata" controls playsInline autoPlay muted className="max-lg:mx-auto aspect-video lg:w-[75%] mt-6">
                      <source src={edge?.videourl || ''} type="video/mp4" />
                    </video>
                  )}
                </>
              )}
            </div>
          </div>
        )
      })}
    </>
  )
}

function CtaLinks() {
  const { page } = useContext(SectionContext)

  return (
    <ul className="children-a:inline-flex children-a:items-center children-li:py-2 [&_svg]:mr-2 [&_path]:lg:fill-hh-blue font-light mt-4 text-sm">
      <li>
        <a className="group" href={`tel:${page?.recap?.recruiter?.recap?.phoneNumber}`} target="_blank" rel="noreferrer">
          <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M15.1129 21C11.4315 21 7.5002 19.6473 4.42641 16.5736C1.35774 13.5048 0 9.57616 0 5.8871C0 2.63583 2.63041 0 5.8871 0C6.13869 0 6.36489 0.153152 6.45832 0.386736L9.09427 6.97659C9.22048 7.29209 9.06704 7.65011 8.75159 7.77632L5.85601 8.93452C6.06084 12.2657 8.73481 14.9395 12.0655 15.1441L13.2237 12.2485C13.3496 11.9335 13.7075 11.7795 14.0234 11.9058L20.6132 14.5417C20.8468 14.6351 21 14.8613 21 15.1129C21 18.3642 18.3696 21 15.1129 21ZM5.47764 1.24811C3.12137 1.45199 1.23047 3.42788 1.23047 5.8871C1.23047 9.59524 2.67446 13.0814 5.29651 15.7034C7.91852 18.3255 11.4047 19.7695 15.1129 19.7695C17.5713 19.7695 19.5479 17.8797 19.7519 15.5224L14.1377 13.2767L13.0482 16.0004C12.9548 16.2339 12.7285 16.3871 12.477 16.3871C8.14041 16.3871 4.6129 12.8596 4.6129 8.5237C4.6129 8.27215 4.76606 8.04521 4.99964 7.95182L7.72332 6.86232L5.47764 1.24811Z"
              fill="#323232"
            />
          </svg>
          <span className="group-hover:underline">{page?.recap?.recruiter?.recap?.phoneNumber}</span>
        </a>
      </li>
      <li>
        <LosseLink target="_blank" rel="noreferrer" className="group" to={page?.recap?.recruiter?.recap?.whatsapp}>
          <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M17.8796 3.04958C15.9069 1.08419 13.2825 0.00112152 10.4894 0C7.70261 0 5.07408 1.08211 3.08835 3.04701C1.09909 5.01528 0.00256348 7.63099 0 10.4027V10.406V10.4079C0.000320435 12.0855 0.441078 13.7779 1.27773 15.3227L0.0286789 21L5.77135 19.6937C7.2258 20.4267 8.84864 20.8132 10.4854 20.8138H10.4896C13.2759 20.8138 15.9044 19.7316 17.8905 17.7665C19.8815 15.7966 20.9785 13.1843 20.9798 10.4108C20.9806 7.65678 19.8798 5.04252 17.8796 3.04958ZM10.4894 19.1748H10.4857C9.01607 19.1742 7.55985 18.8052 6.27475 18.1074L6.00318 17.96L2.18456 18.8286L3.01401 15.059L2.85411 14.7832C2.05911 13.4123 1.63902 11.8989 1.63902 10.4061C1.64207 5.57444 5.61193 1.63902 10.4891 1.63902C12.8453 1.63998 15.0591 2.55338 16.7228 4.21067C18.4117 5.89359 19.3414 8.0953 19.3406 10.4103C19.3387 15.2431 15.368 19.1748 10.4894 19.1748Z"
              fill="#323232"
            />
            <path
              d="M7.63474 5.8186H7.17492C7.01486 5.8186 6.75499 5.87852 6.53517 6.11773C6.31519 6.35709 5.69531 6.93564 5.69531 8.11227C5.69531 9.28891 6.5552 10.4258 6.67504 10.5855C6.79504 10.7451 8.33489 13.2368 10.7737 14.1954C12.8006 14.992 13.2132 14.8335 13.653 14.7937C14.0929 14.7539 15.0727 14.2153 15.2726 13.6569C15.4726 13.0986 15.4726 12.6198 15.4127 12.5199C15.3526 12.4202 15.1925 12.3604 14.9527 12.2409C14.7127 12.1212 13.5367 11.5329 13.3167 11.453C13.0967 11.3733 12.9368 11.3334 12.7768 11.573C12.6167 11.812 12.1455 12.3651 12.0055 12.5247C11.8656 12.6844 11.7256 12.7044 11.4856 12.5847C11.2456 12.4647 10.4807 12.2077 9.56408 11.3932C8.85063 10.7592 8.35556 9.95077 8.21553 9.7114C8.07566 9.4722 8.20063 9.34274 8.32095 9.22338C8.42878 9.11636 8.57442 8.96992 8.69442 8.83037C8.81426 8.69066 8.84839 8.591 8.9285 8.43143C9.00845 8.27185 8.96839 8.13214 8.90847 8.01262C8.84839 7.89294 8.38792 6.71037 8.17452 6.23741H8.17468C7.99491 5.83911 7.80569 5.82565 7.63474 5.8186Z"
              fill="#323232"
            />
          </svg>
          <span className="group-hover:underline">{`WhatsApp ${page?.recap?.recruiter?.recap?.firstName}`}</span>
        </LosseLink>
      </li>
      <li>
        <a className="group" href={`mailto:${page?.recap?.recruiter?.recap?.email}`} target="_blank" rel="noreferrer">
          <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M19.1543 3.11719H1.8457C0.830074 3.11719 0 3.94361 0 4.96289V16.0371C0 17.0568 0.830607 17.8828 1.8457 17.8828H19.1543C20.1699 17.8828 21 17.0564 21 16.0371V4.96289C21 3.94337 20.1695 3.11719 19.1543 3.11719ZM18.8709 4.34766C18.2741 4.94636 11.2647 11.9786 10.9768 12.2675C10.7362 12.5088 10.2639 12.5089 10.0232 12.2675L2.12912 4.34766H18.8709ZM1.23047 15.8109V5.18909L6.52415 10.5L1.23047 15.8109ZM2.12912 16.6523L7.39282 11.3715L9.15178 13.1362C9.87258 13.8593 11.1277 13.8591 11.8483 13.1362L13.6072 11.3715L18.8709 16.6523H2.12912ZM19.7695 15.8109L14.4758 10.5L19.7695 5.18909V15.8109Z"
              fill="#323232"
            />
          </svg>
          <span className="group-hover:underline">{page?.recap?.recruiter?.recap?.email}</span>
        </a>
      </li>
    </ul>
  )
}
